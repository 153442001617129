import { getCurrencyText, pluralize } from '@rentpath/web-utils'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { memo, useRef } from 'react'
import { Tooltip } from '../../../components/tooltip/tooltip'
import {
  InfoIconSRP as InfoIcon,
  PRICE_DROP_BADGE_TEMPLATE,
} from '@brand/config/price-drop-badge'
import type { ListingCard_ListingFragment } from '../__generated__/listing-card.gql'
import styles from './price-drop-badge.module.css'
import type { ListingCardPreview_ListingFragment } from '../__generated__/listing-card-preview.gql'
import clsx from 'clsx'

type PriceDropBadgeProps = {
  listing:
    | ListingCard_ListingFragment
    | Partial<ListingCardPreview_ListingFragment>
  isListingCardPreviewRedesign?: boolean
  isThankYouModal?: boolean
  className?: string
}

export const PriceDropBadge = memo(function PriceDropBadge({
  listing,
  isListingCardPreviewRedesign,
  isThankYouModal,
  className,
}: PriceDropBadgeProps) {
  const tooltipContainerRef = useRef<HTMLDivElement>(null)
  const caretAnchorRef = useRef<HTMLDivElement>(null)

  const isPropertyComparisonEnabled = useFeatureVariable<boolean>(
    ['property-comparison', 'isEnabled'],
    false
  )

  if (!listing.hasPriceDrops) return null

  let min = Infinity
  let max = 0
  let dropCount = 0

  listing.priceDrops?.byFloorplanId.forEach((priceDrop) => {
    if (priceDrop?.priceDrop && priceDrop.priceDrop > 0) {
      dropCount += 1
      min = Math.min(min, priceDrop.priceDrop)
      max = Math.max(max, priceDrop.priceDrop)
    }
  })

  if (dropCount < 1) return null

  const floorplanText = pluralize(dropCount, `${dropCount} floor plan`)
  const rangeText =
    min === max
      ? `${getCurrencyText(min)}`
      : `${getCurrencyText(min)}-${getCurrencyText(max)}`
  const badgeText = PRICE_DROP_BADGE_TEMPLATE.replace(
    /\$?{price}/,
    getCurrencyText(max)
  )

  return (
    <div
      data-tid="lower-price-badge"
      className={clsx(
        className,
        isPropertyComparisonEnabled
          ? styles.badgePropertyComparison
          : styles.badge,
        isListingCardPreviewRedesign && styles.listingCardPreviewRedesign,
        isThankYouModal && styles.thankYouModalBadge
      )}
      ref={tooltipContainerRef}
    >
      {badgeText}
      <div className={styles.caretAnchor} ref={caretAnchorRef}>
        <InfoIcon className={styles.infoIcon} />
      </div>
      <Tooltip
        containerRef={tooltipContainerRef}
        caretAnchorRef={caretAnchorRef}
        className={styles.tooltip}
      >
        {floorplanText} dropped by <strong>{rangeText}</strong> over the past
        month.
      </Tooltip>
    </div>
  )
})
