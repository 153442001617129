import { getBrandWebsite } from '@brand/config/brand-config'
import { splitNameToFirstLast } from '@rentpath/form-validation'
import { LeadChannel, LeadTour } from '../../__generated__/api-types'
import { getLeadDeviceType } from '../lead-form/utils/get-lead-device-type'
import { createFacebookDataForLead } from '../lead-form/utils/create-facebook-data-for-lead'
import { getMessageWithIntent } from '../lead-form/utils/get-message-with-intent'
import { getLeadMessagePlaceholder } from '../lead-form/utils/get-lead-message-placeholder'
import { useRequestData } from '../request-data/pages-router/use-request-data'

import type { OneClickCookieData } from './one-click-lead-cookie.store'
import type {
  ListingAvailabilityStatus,
  LeadSubmissionInput,
  LeadDevice,
} from '../../__generated__/api-types'
import { buildRequestTourMessage } from '../../features/request-a-tour/contact-day-picker-form/build-request-tour-message'
import { REQUEST_A_TOUR } from './one-click-lead.const'

type UseMakeOneClickLeadArgs = {
  oneClickData: OneClickCookieData
  listing?: {
    id: string
    name?: string | null
    isBasic: boolean
    tplsource?: string | null
    revenue?: number | null
    availabilityStatus?: ListingAvailabilityStatus
  }
  type?: 'email' | 'tour'
  showPaidLeadMessage?: boolean
}

export function useMakeOneClickLead({
  oneClickData,
  // including the minimum necessary listing data to make a lead
  listing,
  type,
  showPaidLeadMessage,
}: UseMakeOneClickLeadArgs): LeadSubmissionInput | null {
  const { sessionId, visitId, isMobile, useGDPR, ip, sem } = useRequestData()
  const placeholderMessage = getLeadMessagePlaceholder(
    listing,
    showPaidLeadMessage
  )
  let taggingHitId: string | undefined = ''
  let device = '' as LeadDevice

  let fbData

  if (!listing) {
    return null
  }

  const leadTypeId = !listing.isBasic
    ? LeadChannel.Active
    : LeadChannel.Inactive

  const includeRequestTourDetails =
    oneClickData.mostRecentLeadTypeSubmitted === REQUEST_A_TOUR &&
    type !== 'email'

  if (typeof window !== 'undefined') {
    device = getLeadDeviceType()
    fbData = createFacebookDataForLead({
      email: oneClickData.email,
      listingId: listing.id,
      ip,
      revenue: listing.revenue,
      useGDPR,
      sessionId,
      visitId,
    })
    taggingHitId = window.hit_id
  }

  const message = includeRequestTourDetails
    ? buildRequestTourMessage({
        name: oneClickData.name,
        propertyName: listing.name || '',
        dateTime: oneClickData.dateTime || '',
        customerMessage: '',
      })
    : getMessageWithIntent(
        {
          ...oneClickData,
          firstName: oneClickData.name.split(' ')[0],
          lastName: oneClickData.name.split(' ').slice(1).join(' '),
          context: 'one-click-lead',
          message: placeholderMessage,
          optInNewsletter: false,
          requestATour: false,
          submittingApplication: false,
        },
        listing,
        undefined,
        showPaidLeadMessage
      )

  const { firstName, lastName } = splitNameToFirstLast(oneClickData.name)

  const nameInput =
    firstName && lastName
      ? { firstName, lastName }
      : { fullName: oneClickData.name }

  const lead: LeadSubmissionInput = {
    ...nameInput,
    applicationOptIn: oneClickData.applicationOptIn,
    campaignId: sem.marketingCampaignId,
    context: 'one-click-lead',
    device,
    email: oneClickData.email,
    evTransId: `${visitId}.${sessionId}_${listing.id}`,
    facebookData: fbData,
    leadTypeId,
    listingId: listing.id,
    message,
    moveDate: oneClickData.moveDate,
    phone: oneClickData.phone,
    taggingHitId,
    tourType: oneClickData.tourRequest ? LeadTour.Unspecified : null,
    website: getBrandWebsite(isMobile),
    sessionId: `${visitId}.${sessionId}`,
    tvisit: `${visitId}.${sessionId}`,
  }

  if (includeRequestTourDetails) {
    lead.tourDateTime = oneClickData.requestedTourDate
  }

  return lead
}
