export const PASSWORD_MIN_LENGTH = 8

export const NAME_MIN_LENGTH = 2

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// The first name is considered the first string of non-whitespace characters.
// The last name is whatever remains of the entire string. The API requirement
// is that the first and last name contain at least 2 characters.
export const FIRST_LAST_NAME_REGEX = /^\s*\S{2,}\s+\S{2,}/
export const SPLIT_NAME_REGEX = /^\s*\S{2,}\s*/

// Regular expression to verify phone number contains only the characters
// we want to allow in the field: digits, whitespace, certain special chars,
// but no alphanumeric characters.
const PHONE_REGEX = /^[\d\s()+,.\-_]+$/

export const isValidEmail = (email: string): boolean => EMAIL_REGEX.test(email)

export const isValidFirstLastName = (name: string): boolean =>
  FIRST_LAST_NAME_REGEX.test(name)

export const isValidSplitName = (name: string): boolean =>
  SPLIT_NAME_REGEX.test(name)

// Helper function to determine if a field has some kind of input
export function hasNonSpace(str: string): boolean {
  return /\S/.test(str)
}

// Helper function to strip out non-digits from the phone number
export function cleanPhone(phone: string): string {
  return phone.replace(/\D/g, '')
}

/**
 * Helper function for phone input validation.
 * We will eventually strip out everything but the digits in any case,
 * but for validation we will allow certain characters like ()
 * because users might tend to use those.
 * @param {string} phone - Phone Number to clean up
 * @returns {string} Only numbers, no non-digits
 */
export function isValidPhone(phone: string): boolean {
  const digitsOnly = cleanPhone(phone)
  const firstDigit = digitsOnly[0]

  return (
    PHONE_REGEX.test(phone) &&
    digitsOnly.length === 10 &&
    !['0', '1'].includes(firstDigit)
  )
}

/**
 * Function to split a single name field into first/last names.
 * This corresponds to the isValidFirstLastName validator and the
 * FIRST_LAST_NAME_REGEX regex.
 */
export function splitNameToFirstLast(name: string) {
  const matches = name.match(/^\s*(\S{2,})\s+(\S{2}.*)$/)

  if (matches && matches[1] && matches[2]) {
    return {
      firstName: matches[1].trim(),
      // last name can contain spaces, so collapse multiple spaces down to
      // single space
      lastName: matches[2].trim().split(/\s+/).join(' '),
    }
  }

  // Form validation should be done with validateFirstLastName() so that this
  // code is never reached.
  return {
    firstName: name.trim(),
    lastName: '',
  }
}
